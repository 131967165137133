import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    company: '',
    firstName: '',
    lastName: '',
    email: '',
    tel: '',
    inquiryType: 'foobar',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(JSON.stringify(formData));
    try {
      const response = await fetch('https://api.ayrom.io/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        // フォームの送信成功時の処理
        console.log('Form submitted successfully!');
        console.log(response.ok);
      } else {
        // フォームの送信失敗時の処理
        console.error('Form submission failed.');
      }
    } catch (error) {
      console.error('Form submission failed.', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Company:
        <input
          type="text"
          name="company"
          value={formData.company}
          onChange={handleChange}
        />
      </label>
      <label>
        FirstName:
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
      </label>
      <label>
        LastName:
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
      </label>
      <label>
        Email:
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
      </label>
      <label>
        Phone:
        <input
          type="tel"
          name="tel"
          value={formData.tel}
          onChange={handleChange}
        />
      </label>
      <label>
        Message:
        <textarea
          type="text"
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
      </label>
      <button type="submit">Submit</button>
    </form>
  );
};

export default ContactForm;
